<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Images-->
        <div class="grid grid-cols-5 -mx-2">
          <div class="p-2">
            <!-- Image 1 -->
            <template v-if="product.image1">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image1" alt="img" class="responsive">
              </div>
              <!-- Image upload Buttons -->
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image1=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 2-->
            <template v-if="product.image2">

              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image2" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image2=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 3 -->
            <template v-if="product.image3">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image3" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image3=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 4 -->
            <template v-if="product.image4">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image4" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image4=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 5 -->
            <template v-if="product.image5">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image5" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image5=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 6 -->
            <template v-if="product.image6">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image6" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image6=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 7 -->
            <template v-if="product.image7">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image7" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image7=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 8 -->
            <template v-if="product.image8">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image8" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image8=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 9 -->
            <template v-if="product.image9">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image9" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image9=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 10 -->
            <template v-if="product.image10">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image10" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image10=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 11 -->
            <template v-if="product.image11">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image11" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image11=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 12 -->
            <template v-if="product.image12">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image12" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image12=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 13 -->
            <template v-if="product.image13">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image13" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image13=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 14 -->
            <template v-if="product.image14">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image14" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image14=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 15 -->
            <template v-if="product.image15">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image15" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image15=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="p-2">
            <!-- Image 16 -->
            <template v-if="product.image16">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image16" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image16=null">Eliminar</span>
              </div>
            </template>
          </div>
        </div>
        <div class="grid grid-cols-5 -mx-2">
          <!-- Upload image 1 -->
          <div class="upload-img mt-5 p-2" v-if="!product.image1">
            <input id="image1" type="file" class="hidden" ref="uploadImgInput1" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput1.click()">1 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 2-->
          <div class="upload-img mt-5 p-2" v-if="!product.image2">
            <input id="image2" type="file" class="hidden" ref="uploadImgInput2" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput2.click()">2 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 3-->
          <div class="upload-img mt-5 p-2" v-if="!product.image3">
            <input id="image3" type="file" class="hidden" ref="uploadImgInput3" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput3.click()">3 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 4-->
          <div class="upload-img mt-5 p-2" v-if="!product.image4">
            <input id="image4" type="file" class="hidden" ref="uploadImgInput4" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput4.click()">4 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 5-->
          <div class="upload-img mt-5 p-2" v-if="!product.image5">
            <input id="image5" type="file" class="hidden" ref="uploadImgInput5" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput5.click()">5 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 6-->
          <div class="upload-img mt-5 p-2" v-if="!product.image6">
            <input id="image6" type="file" class="hidden" ref="uploadImgInput6" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput6.click()">6 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 7-->
          <div class="upload-img mt-5 p-2" v-if="!product.image7">
            <input id="image7" type="file" class="hidden" ref="uploadImgInput7" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput7.click()">7 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 8-->
          <div class="upload-img mt-5 p-2" v-if="!product.image8">
            <input id="image8" type="file" class="hidden" ref="uploadImgInput8" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput8.click()">8 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 9-->
          <div class="upload-img mt-5 p-2" v-if="!product.image9">
            <input id="image9" type="file" class="hidden" ref="uploadImgInput9" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput9.click()">9 (1080x1350)</vs-button>
          </div>

          <!-- Upload image 10-->
          <div class="upload-img mt-5 p-2" v-if="!product.image10">
            <input id="image10" type="file" class="hidden" ref="uploadImgInput10" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput10.click()">10 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 11-->
          <div class="upload-img mt-5 p-2" v-if="!product.image11">
            <input id="image11" type="file" class="hidden" ref="uploadImgInput11" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput11.click()">11 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 12-->
          <div class="upload-img mt-5 p-2" v-if="!product.image12">
            <input id="image12" type="file" class="hidden" ref="uploadImgInput12" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput12.click()">12 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 13-->
          <div class="upload-img mt-5 p-2" v-if="!product.image13">
            <input id="image13" type="file" class="hidden" ref="uploadImgInput13" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput13.click()">13 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 14-->
          <div class="upload-img mt-5 p-2" v-if="!product.image14">
            <input id="image14" type="file" class="hidden" ref="uploadImgInput14" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput14.click()">14 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 15-->
          <div class="upload-img mt-5 p-2" v-if="!product.image15">
            <input id="image15" type="file" class="hidden" ref="uploadImgInput15" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput15.click()">15 (1080x1350)
            </vs-button>
          </div>

          <!-- Upload image 16-->
          <div class="upload-img mt-5 p-2" v-if="!product.image16">
            <input id="image16" type="file" class="hidden" ref="uploadImgInput16" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput16.click()">16 (1080x1350)
            </vs-button>
          </div>
        </div>
        <!--End-->

        <!-- Name -->
        <div class="mt-5">
          <vs-input @input="setSlug" @blur="product.name= trimString(product.name)" label="Nombre*"
                    v-model="product.name"
                    class="w-full" name="name"
                    v-validate="'required|min:3|max:150'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>

        <!-- Colors -->
        <div class="mt-5">
          <label class="vs-select--label" for="colors">Selecciona colores disponibles</label>
          <v-select multiple @input="setSlug" id="colors" label="name" :options="colors"
                    v-model="product.color"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="color"/>
          <span class="text-danger text-sm"
                v-show="errors.has('color')">{{ errors.first('color') }}</span>
        </div>
        <!--End-->

        <!-- Categories -->
        <div class="mt-5">
          <label class="vs-select--label" for="categories">Selecciona una categoria*</label>
          <v-select id="categories" label="name" :options="categories" v-model="product.category"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="category" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('category')">{{ errors.first('category') }}</span>
        </div>
        <!--End-->

        <!-- Occasions -->
        <div class="mt-5">
          <label class="vs-select--label" for="categories">Selecciona una ocasión*</label>
          <v-select multiple id="occasion" label="name" :options="occasions" v-model="product.occasion"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="occasion" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('occasion')">{{ errors.first('occasion') }}</span>
        </div>
        <!--End-->

        <!-- SKU -->
        <div class="mt-5">
          <vs-input @blur="product.sku= trimString(product.sku)" label="SKU" v-model="product.sku"
                    class="w-full" name="sku"
                    v-validate="'required|min:3|max:50'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('sku')">{{ errors.first('sku') }}</span>
        </div>

        <!-- Slug -->
        <div class="mt-5">
          <vs-input :disabled="true" @blur="product.slug= trimString(product.slug)" label="Slug"
                    v-model="product.slug"
                    class="w-full" name="slug"
                    v-validate="'min:3'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
        </div>

        <!---Prices--->
        <div class="flex -mx-2 mt-5">
          <!-- Normal price -->
          <div class="w-1/2 p-2">
            <vs-input type="number" label="Precio normal" v-model.number="product.normalPrice"
                      class="w-full"
                      name="normalPrice"
                      v-validate="'min_value:1'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('normalPrice')">{{ errors.first('normalPrice') }}</span>
          </div>
          <div class="w-1/2 p-2">
            <!-- Price Discount -->
            <vs-input type="number" label="Precio con descuento" v-model.number="product.priceDiscount"
                      class="w-full"
                      name="priceDiscount"
                      v-validate="'min_value:1'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('priceDiscount')">{{ errors.first('priceDiscount') }}</span>
          </div>
        </div>
        <!--End-->

        <!-- Description -->
        <div class="mt-5">
          <vs-textarea rows="5" @blur="product.description= trimString(product.description)"
                       label="Descripción"
                       v-model="product.description" class="w-full"
                       name="description"
                       v-validate="'min:5|max:1000'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>

        <!-- Related products -->
        <div class="mt-5">
          <label class="vs-select--label" for="relateProducts">Selecciona productos relacionados</label>
          <v-select multiple id="relateProducts" label="name" :options="listForRelatedProducts"
                    v-model="product.relatedProducts"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="relatedProducts"/>
        </div>
        <!--End-->

        <!-- Order -->
        <vs-input data-vv-as="order" label="Orden*"
                  v-model.number="product.order"
                  class="mt-5 w-full" name="order"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('order')">{{ errors.first('order') }}</span>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { auth, db, FieldValue, storage } from '@/firebase/firebaseConfig'

export default {
  name: 'NewProduct',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    listForRelatedProducts: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    occasions: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      product: {
        stock: 0,
        normalPrice: 0,
        priceDiscount: 0,
        isVariant: 'false'
      },
      newImage1: null,
      newImage2: null,
      newImage3: null,
      newImage4: null,
      newImage5: null,
      newImage6: null,
      newImage7: null,
      newImage8: null,
      newImage9: null,
      newImage10: null,
      newImage11: null,
      newImage12: null,
      newImage13: null,
      newImage14: null,
      newImage15: null,
      newImage16: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.initValues()
        this.$validator.reset()
      }
    },
    product: {
      handler () {
        if (this.product.image1 === null) {
          this.newImage1 = null
        }
        if (this.product.image2 === null) {
          this.newImage2 = null
        }
        if (this.product.image3 === null) {
          this.newImage3 = null
        }
        if (this.product.image4 === null) {
          this.newImage4 = null
        }
        if (this.product.image5 === null) {
          this.newImage5 = null
        }
        if (this.product.image6 === null) {
          this.newImage6 = null
        }
        if (this.product.image7 === null) {
          this.newImage7 = null
        }
        if (this.product.image8 === null) {
          this.newImage8 = null
        }
        if (this.product.image9 === null) {
          this.newImage9 = null
        }
        if (this.product.image10 === null) {
          this.newImage10 = null
        }
        if (this.product.image11 === null) {
          this.newImage11 = null
        }
        if (this.product.image12 === null) {
          this.newImage12 = null
        }
        if (this.product.image13 === null) {
          this.newImage13 = null
        }
        if (this.product.image14 === null) {
          this.newImage14 = null
        }
        if (this.product.image15 === null) {
          this.newImage15 = null
        }
        if (this.product.image16 === null) {
          this.newImage16 = null
        }
      },
      deep: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Images
          if (this.newImage1 != null) {
            this.product.image1 = await this.submitImages(this.newImage1)
          }
          if (this.newImage2 != null) {
            this.product.image2 = await this.submitImages(this.newImage2)
          }
          if (this.newImage3 != null) {
            this.product.image3 = await this.submitImages(this.newImage3)
          }
          if (this.newImage4 != null) {
            this.product.image4 = await this.submitImages(this.newImage4)
          }
          if (this.newImage5 != null) {
            this.product.image5 = await this.submitImages(this.newImage5)
          }
          if (this.newImage6 != null) {
            this.product.image6 = await this.submitImages(this.newImage6)
          }
          if (this.newImage7 != null) {
            this.product.image7 = await this.submitImages(this.newImage7)
          }
          if (this.newImage8 != null) {
            this.product.image8 = await this.submitImages(this.newImage8)
          }
          if (this.newImage9 != null) {
            this.product.image9 = await this.submitImages(this.newImage9)
          }
          if (this.newImage10 != null) {
            this.product.image10 = await this.submitImages(this.newImage10)
          }
          if (this.newImage11 != null) {
            this.product.image11 = await this.submitImages(this.newImage11)
          }
          if (this.newImage12 != null) {
            this.product.image12 = await this.submitImages(this.newImage12)
          }
          if (this.newImage13 != null) {
            this.product.image13 = await this.submitImages(this.newImage13)
          }
          if (this.newImage14 != null) {
            this.product.image14 = await this.submitImages(this.newImage14)
          }
          if (this.newImage15 != null) {
            this.product.image15 = await this.submitImages(this.newImage15)
          }
          if (this.newImage16 != null) {
            this.product.image16 = await this.submitImages(this.newImage16)
          }
          // End
          let obj = {
            ...this.product,
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp(),
            stock: 0,
            state: true,
            isPriceDifferent: this.product.normalPrice !== this.product.priceDiscount
          }
          // Save in firestore
          const doc = await db.collection('products').add({
            ...obj,
            createdAt: FieldValue.serverTimestamp()
          })
          obj.id = doc.id
          this.$emit('closeSidebar')
          this.$emit('add', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Producto',
            text: 'Producto creado correctamente.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Save image in firebase storage
     * @param image
     * @returns {Promise<unknown>}
     */
    submitImages (image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = storage.ref(`products/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    /**
     * Load image
     * @param input
     */
    updateImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (input.srcElement.id === 'image1') {
            this.$set(this.product, 'image1', e.target.result)
            this.newImage1 = input.target.files[0]
            if (this.newImage1.size > 5000000) {
              this.product.image1 = null
              this.newImage1 = null
            }
          }
          else if (input.srcElement.id === 'image2') {
            this.$set(this.product, 'image2', e.target.result)
            this.newImage2 = input.target.files[0]
            if (this.newImage2.size > 5000000) {
              this.product.image2 = null
              this.newImage2 = null
            }
          }
          else if (input.srcElement.id === 'image3') {
            this.$set(this.product, 'image3', e.target.result)
            this.newImage3 = input.target.files[0]
            if (this.newImage3.size > 5000000) {
              this.product.image3 = null
              this.newImage3 = null
            }
          }
          else if (input.srcElement.id === 'image4') {
            this.$set(this.product, 'image4', e.target.result)
            this.newImage4 = input.target.files[0]
            if (this.newImage4.size > 5000000) {
              this.product.image4 = null
              this.newImage4 = null
            }
          }
          else if (input.srcElement.id === 'image5') {
            this.$set(this.product, 'image5', e.target.result)
            this.product.image5 = e.target.result
            this.newImage5 = input.target.files[0]
            if (this.newImage5.size > 5000000) {
              this.product.image5 = null
              this.newImage5 = null
            }
          }
          else if (input.srcElement.id === 'image6') {
            this.$set(this.product, 'image6', e.target.result)
            this.product.image6 = e.target.result
            this.newImage6 = input.target.files[0]
            if (this.newImage6.size > 5000000) {
              this.product.image6 = null
              this.newImage6 = null
            }
          }
          else if (input.srcElement.id === 'image7') {
            this.$set(this.product, 'image7', e.target.result)
            this.product.image7 = e.target.result
            this.newImage7 = input.target.files[0]
            if (this.newImage7.size > 5000000) {
              this.product.image7 = null
              this.newImage7 = null
            }
          }
          else if (input.srcElement.id === 'image8') {
            this.$set(this.product, 'image8', e.target.result)
            this.product.image8 = e.target.result
            this.newImage8 = input.target.files[0]
            if (this.newImage8.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image8 = null
              this.newImage8 = null
            }
          }
          else if (input.srcElement.id === 'image9') {
            this.$set(this.product, 'image9', e.target.result)
            this.product.image9 = e.target.result
            this.newImage9 = input.target.files[0]
            if (this.newImage9.size > 5000000) {
              this.product.image9 = null
              this.newImage9 = null
            }
          }
          else if (input.srcElement.id === 'image10') {
            this.$set(this.product, 'image10', e.target.result)
            this.product.image10 = e.target.result
            this.newImage10 = input.target.files[0]
            if (this.newImage10.size > 5000000) {
              this.product.image10 = null
              this.newImage10 = null
            }
          }
          else if (input.srcElement.id === 'image11') {
            this.$set(this.product, 'image11', e.target.result)
            this.product.image11 = e.target.result
            this.newImage11 = input.target.files[0]
            if (this.newImage11.size > 5000000) {
              this.product.image11 = null
              this.newImage11 = null
            }
          }
          else if (input.srcElement.id === 'image12') {
            this.$set(this.product, 'image12', e.target.result)
            this.product.image12 = e.target.result
            this.newImage12 = input.target.files[0]
            if (this.newImage12.size > 5000000) {
              this.product.image12 = null
              this.newImage12 = null
            }
          }
          else if (input.srcElement.id === 'image13') {
            this.$set(this.product, 'image13', e.target.result)
            this.product.image13 = e.target.result
            this.newImage13 = input.target.files[0]
            if (this.newImage13.size > 5000000) {
              this.product.image13 = null
              this.newImage13 = null
            }
          }
          else if (input.srcElement.id === 'image14') {
            this.$set(this.product, 'image14', e.target.result)
            this.product.image14 = e.target.result
            this.newImage14 = input.target.files[0]
            if (this.newImage14.size > 5000000) {
              this.product.image14 = null
              this.newImage14 = null
            }
          }
          else if (input.srcElement.id === 'image15') {
            this.$set(this.product, 'image15', e.target.result)
            this.product.image15 = e.target.result
            this.newImage15 = input.target.files[0]
            if (this.newImage15.size > 5000000) {
              this.product.image15 = null
              this.newImage15 = null
            }
          }
          else if (input.srcElement.id === 'image16') {
            this.$set(this.product, 'image16', e.target.result)
            this.product.image16 = e.target.result
            this.newImage16 = input.target.files[0]
            if (this.newImage16.size > 5000000) {
              this.product.image16 = null
              this.newImage16 = null
            }
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Set slug
     */
    setSlug () {
      this.$set(this.product, 'slug', this.getSlug(this.product.name))
    },
    /**
     * Get slug
     * @param name
     * @returns {string}
     */
    getSlug (name) {
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
          .normalize()
      return text
    },
    /**
     * Reset values
     */
    initValues () {
      this.product = {
        stock: 0,
        normalPrice: 0,
        priceDiscount: 0,
        isVariant: 'false',
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        image9: null,
        image10: null,
        image11: null,
        image12: null,
        image13: null,
        image14: null,
        image15: null,
        image16: null
      }
      this.newImage1 = null
      this.newImage2 = null
      this.newImage3 = null
      this.newImage4 = null
      this.newImage5 = null
      this.newImage6 = null
      this.newImage7 = null
      this.newImage8 = null
      this.newImage9 = null
      this.newImage10 = null
      this.newImage11 = null
      this.newImage12 = null
      this.newImage13 = null
      this.newImage14 = null
      this.newImage15 = null
      this.newImage16 = null
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
