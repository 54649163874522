<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Opciones de {{ product.name }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <!--Title--->
      <div class="pl-6 pr-6 mt-5">
        <vs-input label="Nombre de opción*"
                  v-model="product.optionName"
                  class="w-full"/>
      </div>
      <!---End-->
      <div class="pl-6 pr-6 mt-6 mb-6">
        <hr>
      </div>
      <!--Form-->
      <div class="pl-6 pr-6 mb-8">
        <div class="flex -mx-2">
          <!-- Name -->
          <div class="w-1/2 p-2">
            <vs-input label="Nombre*"
                      v-model="newOption.name"
                      class="w-full"/>
          </div>
          <!-- Image -->
          <div class="w-1/2 p-2">
            <!-- Upload -->
            <div class="upload-img mt-5" v-if="!newOption.image">
              <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button @click="$refs.uploadImgInput.click()">Subir imagen</vs-button>
            </div>
            <!--End-->
            <!-- Image -->
            <div class="mt-5" v-if="newOption.image">
              <!-- Image Container -->
              <div class="img-container w-64 mx-auto flex items-center justify-center">
                <img :src="newOption.image" alt="img" class="responsive h-8 w-8 object-cover">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img mb-4 text-center">
                <vs-button type="flat" color="#999" @click="newOption.image=null">Eliminar imágen</vs-button>
              </div>
            </div>
            <!--End-->
          </div>
        </div>

        <vs-button class="mr-6 mt-2" @click="addOption" :disabled="!isFormValid || progress">
          Agregar
        </vs-button>
      </div>
      <!--End-->
      <div class="pl-6 pr-6 mt-6 mb-6">
        <hr>
      </div>
      <!--Product options-->
      <div class="pl-6 pr-6" v-for="(o, index) in options" :key="index">
        <div class="grid grid-cols-7 gap-3">
          <!--Options list-->
          <div class="col-span-6">
            <div class="flex -mx-2">
              <!-- Name -->
              <div class="w-1/2 p-2">
                <vs-input label="Nombre"
                          v-model="o.name"
                          class="w-full"/>
              </div>
              <!--End-->
              <!-- Image -->
              <div class="w-1/2 p-2 mt-4">
                <img class="h-8" :src="o.image" :alt="o.name">
              </div>
              <!--End-->
              <!---Order-->
              <div>
                <vs-input label="Orden"
                          v-model="o.order"
                          class="w-full"/>
              </div>
              <!--End-->
            </div>
          </div>
          <!--End-->

          <div class="col-span-1 flex justify-center items-end pb-5">
            <feather-icon style="cursor:pointer" icon="TrashIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="deleteObject(o)"/>
          </div>
        </div>
      </div>
      <!--End-->
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

let _ = require('lodash')

import {db, auth, storage, FieldValue} from '@/firebase/firebaseConfig'

export default {
  name: 'Options',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [
    trimString
  ],
  data () {
    return {
      product: {},
      options: [],
      newOption: {},
      newImage: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      changes: false,
      progress: false,
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (val) {
        await this.$validator.reset()
        this.product = _.cloneDeep(this.data)
        this.newOption = {}
        this.newImage = null
        this.options = []
        await this.getOptions()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        this.progress = true
        const batch = db.batch()
        this.options.forEach((s) => {
          const docRef = db.collection('products').doc(this.product.id).collection('options').doc(s.id)
          batch.update(docRef, {
            ...s,
            updatedAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid
          })
        })
        // Update product
        const productRef = db.collection('products').doc(this.product.id)
        batch.update(productRef, {
          optionName: this.product.optionName,
          updatedAt: FieldValue.serverTimestamp()
        })
        await batch.commit()
        this.$emit('closeSidebar')
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'Opciones actualizadas.'
        })

      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Add option to product
     */
    async addOption () {
      try {
        if (this.newOption.name.length >= 0 && this.newOption.image) {
          this.progress = true
          const obj = {
            ...this.newOption,
            createdAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid
          }
          // Submit image
          if (this.newImage != null) {
            obj.image = await this.submitImages()
          }
          // End
          db.collection('products').doc(this.product.id).collection('options').add({
            ...obj
          }).then((doc) => {
            obj.id = doc.id
            this.options.push({
              ...obj
            })
            this.newOption = {}
            this.newImage = null
            this.$validator.reset()
            this.$vs.notify({
              color: 'success',
              title: 'Producto',
              text: 'Talla agregada.'
            })
            this.progress = false
          })
        }
        else {
          this.$vs.notify({
            color: 'warning',
            title: 'Producto',
            text: 'Revisa la información de la talla a ingresar'
          })
        }
      } catch (e) {
        console.log(e)
        this.progress = false
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Delete option
     * @param object
     * @returns {Promise<void>}
     */
    async deleteObject (object) {
      try {
        this.progress = true
        const index = this.options.findIndex((s) => s.id === object.id)
        if (index !== -1) {
          await db.collection('products').doc(this.product.id).collection('options').doc(object.id).delete()
          this.options.splice(index, 1)
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Get options of product
     * @returns {Promise<void>}
     */
    async getOptions () {
      const querySnapshot = await db.collection('products').doc(this.product.id).collection('options').orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let option = {
          id: doc.id,
          ...doc.data(),
          order: doc.data().order || 0
        }
        this.options.push(option)
        this.options.sort((a, b) => a.order - b.order)
      })
    },
    /**
     * Update img
     * @param input
     */
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.newOption, 'image', e.target.result)
          this.newImage = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Save image in firebase
     * @returns {Promise<unknown>}
     */
    submitImages () {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + this.newImage.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension
        let storageRef = storage.ref(`options/${imageNameFinal}`)
        storageRef.put(this.newImage)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 700px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
